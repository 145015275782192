var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-component-flexbox flex justify-center items-center md:items-start items-center mt-12"},[_c('div',{staticClass:"logo-component-container border rounded-md border-gray-300 py-12 px-6 flex flex-col items-center"},[_c('span',{staticClass:"text-2xl text-gray-700 font-light mb-6 w-full login-form-width2 text-center"},[_vm._v(" Recupera tu contraseña ")]),_c('div',{staticClass:"login-form-container"},[_c('div',{staticClass:"login-form"},[_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[(
              _vm.errorLogin != '' ||
                (Array.isArray(_vm.errorLogin) && _vm.errorLogin.length != 0)
            )?_c('alert-box',{staticClass:"login-alert-box",attrs:{"type":"danger","title":'',"errors":_vm.errorLogin}}):_vm._e()],1),_c('transition',{attrs:{"name":"fadeHeight","mode":"out-in"}},[(
              _vm.successLogin != '' ||
                (Array.isArray(_vm.successLogin) && _vm.successLogin.length != 0)
            )?_c('alert-box',{staticClass:"login-alert-box",attrs:{"type":"success","title":'',"errors":_vm.successLogin}}):_vm._e()],1),_c('div',{staticClass:"w-full flex flex-col justify-center items-start"},[_c('span',{staticClass:"text-sm text-gray-600"},[_vm._v("Dirección de correo electrónico")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm",class:_vm.errorEmailLogin
                ? 'ring-red-500 border-red-500 border-3 bg-red-50'
                : 'border',attrs:{"name":"email","type":"email","autocomplete":"email","required":"","placeholder":"Correo electrónico"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})]),_c('div',{staticClass:"w-full flex flex-row justify-start items-center mb-4"},[_c('router-link',{staticClass:"text-sm cursor-pointer underline text-green-600 hover:text-green-800",attrs:{"to":"/"}},[_vm._v("¿Ya recordaste tu contraseña?")])],1),_c('div',{staticClass:"w-full my-2"},[_c('button-component',{attrs:{"disabled":_vm.errorLogin.length !== 0 ||
                !_vm.canSubmitLogin ||
                _vm.successLogin.length !== 0 ||
                _vm.errorEmailLogin ||
                _vm.form.email === '',"loading":!_vm.canSubmitLogin},on:{"whenClick":function($event){return _vm.submit()}}},[_c('strong',[_vm._v("Enviar correo electrónico de recuperación")])])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }